import React from "react";

export interface LogoProps {
  onClick: () => void;
}

export const Logo: React.FC<LogoProps> = ({ onClick }) => {
  return (
    <div className="logo" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.9 47.6">
        <path d="M3 0a3 3 0 0 0-3 3v41.6a3 3 0 0 0 3 3h207a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm3 6h201v35.6H6V6zm41.2 6.7c-2.7 0-4.1 1.4-4.1 4.4v13.4c0 3 1.4 4.4 4.1 4.4h4.2c2.8 0 4.2-1.5 4.2-4.4v-2.8H51V31c0 .2 0 .4-.2.5l-.4.2h-2.1c-.2 0-.3 0-.5-.2a.7.7 0 0 1-.1-.5V16.5l.1-.5.5-.2h2.1l.4.2c.2.2.2.3.2.5v2.6h4.5v-2c0-3-1.4-4.4-4.2-4.4h-4.2zm53.3 0c-2.8 0-4.1 1.4-4.1 4.4v17.5h4.6v-7.3h3.4v7.3h4.7V17c0-3-1.4-4.4-4.2-4.4h-4.4zm16.9 0c-2.8 0-4.1 1.4-4.1 4.4v13.4c0 3 1.3 4.4 4.1 4.4h4.2c2.8 0 4.1-1.5 4.1-4.4v-2.8h-4.4V31c0 .2 0 .4-.2.5l-.4.2h-2.1c-.2 0-.4 0-.5-.2a.7.7 0 0 1-.2-.5V16.5c0-.2 0-.3.2-.5l.5-.2h2l.5.2.2.5v2.6h4.4v-2c0-3-1.3-4.4-4.1-4.4h-4.2zm53.3 0c-2.8 0-4.2 1.4-4.2 4.4v13.4c0 3 1.4 4.4 4.2 4.4h4.4c2.7 0 4.1-1.5 4.1-4.4V17.1c0-3-1.4-4.4-4.1-4.4h-4.4zm-151 .3v4h3.5v17.6h4.7V17h3.5v-4H19.7zm14.5 0v21.6H39V13h-4.7zm25 0v21.6h4.6V26h1.5l3.2 8.6h4.7L69 22.9l3.6-9.9h-4.2L65 22h-1.1v-9H59zm22.6 0v4h3.5v17.6H90V17h3.5v-4H81.8zm47.5 0v21.6h4.6V26h1.5l3.2 8.6h4.8L139 22.9l3.7-9.9h-4.3l-3.3 9h-1.2v-9h-4.6zm22.7 0v4h3.5v17.6h4.7V17h3.5v-4H152zm31.4 0v21.6h9.8v-3.9h-5.1v-5.2h4.8V22h-4.8v-5h5.1v-4h-9.8zm-81.7 2.8h2l.5.2.2.5v7.2H101v-7.2c0-.2 0-.3.2-.5l.5-.2zm70.1 0h2.1l.5.2.2.5V31l-.2.5-.5.2h-2c-.3 0-.4 0-.5-.2a.7.7 0 0 1-.2-.5V16.5c0-.2 0-.3.2-.5l.4-.2z" />
      </svg>
    </div>
  );
};
